<script>
import Layout from "../../layouts/horizontal";
import PageHeader from "@/components/page-header";
import { mapActions, mapGetters,   } from 'vuex';

export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: 'Active Indices',
      breadcrumbs: [
        
        {
          text: 'Execution'
        },
        {
          text: 'Active Indices',
          active: true
        }
      ]
    };
  },
  created(){
    this.load();
  },
  computed: {
    ...mapGetters('activeIndices', { items: 'items', loading: 'loading'}),
    
  },
  methods: {
    ...mapActions('activeIndices', { load: 'load'})
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" :is-busy="loading"/>
    <div class="row">
      <div class="col-12">
       <div class="card p-0 cardc8">
            <div class="card-content m-3">
            <div class="">
              <div class="table-responsive mt-3" 
              >
                <table
                  class="table table-centered nowrap"
                  style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                >
                  <thead >
                    <tr>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                   
                      <td>{{item.displayName}}</td>
                    
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>